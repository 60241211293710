/* General ------------------------------------------------------------ */
html {
  font-size: 18px;
}

body{
  background-color: #d1d1d1;
}

.container {
  background-color: #ffffff;
}

@media (max-width: 992px){
  .xmhidden {
    display: none;

  }
}



h1 {
  font-size: 1.5em;
}

h2 {
  font-size: 1.3em;
  margin-top: 10px;
}

h3 {
  font-size: 1.1em;
  margin-top: 10px;
}

p.justified {
  text-align: justify;
}

.rowpadding {
  padding-bottom: 75px;
}

.rowmargin {
  margin-bottom: 20px;
}

div.marginCol {
  margin:10px;
}


section h1.sectionheader {
  text-align: center;
  text-transform: uppercase;
}

section hr.sectionRuler {
  width: 250px;
  height: 2px;
  background-color: #22c0e9;
  margin-bottom: 35px;
}

.highlightColor {
  color: #22c0e9;
}

section footer {
  margin-top: 30px;
}

.centered {
  text-align: center;
}

/* General end -------------------------------------------------------- */


/* Section Company ---------------------------------------------------- */
div.visionBg {
  background-image: linear-gradient(-135deg, #06e6ff, #398de3);
  box-shadow: 3px 3px 4px #00000033;
}

div.missionBg {
  /* background-image: linear-gradient(-135deg, #ffdb45, #d7509f); */
  background-image: linear-gradient(-135deg, #95c1e4, #98e4a6); 
  box-shadow: 3px 3px 4px #00000033;
}

div.valuesBg {
  background-image: linear-gradient(-135deg, #98e15b, #00e1a7);
  box-shadow: 3px 3px 4px #00000033;
}

div.aboutBg {
  background-image: linear-gradient(-135deg, #dfdfdf, #9b9b9b);
  box-shadow: 3px 3px 4px #00000033;
}

div.fullheight {
  position: relative;
  min-height: 500px;
}
div.halfheight{
  position: relative;
  min-height: 300px;
}

svg.align-bottom {
  position: absolute;
    left: 10px;
    bottom: 10px;
}



/* Section Company end ------------------------------------------------ */

/* Section About ------------------------------------------------------ */


ul.timeline {
  list-style-type: none;
  position: relative;
}

ul.timeline:before {
  content: ' ';
  background: #d4d9df;
  display: inline-block;
  position: absolute;
  left: 29px;
  width: 2px;
  height: 100%;
  z-index: 400;
}

ul.timeline > li {
  margin: 0px 0px 50px 0px;
  padding-left: 60px;
}

ul.timeline > li:before {
  content: ' ';
  background: white;
  display: inline-block;
  position: absolute;
  border-radius: 50%;
  border: 3px solid #22c0e9;
  left: 20px;
  width: 20px;
  height: 20px;
  z-index: 400;
}

ul.timeline > li > h1 {
 font-size: 1em;
 margin: 0px 0px 5px 0px;
 padding: 0px;
}

ul.timeline > li > p {
  font-size: 1em;
  margin: 0px;
  padding: 0px;
}
/* Section About end -------------------------------------------------- */